import React from 'react';
import type { AppProps } from 'next/app';
import '@livechat/design-system/dist/design-system.css';
import {
  notificationConnect,
  NotificationProvider,
  ToastConsumer,
} from '@livechat/design-system';

import '../styles/main.css';
import Root from 'components/Root';

function App({ Component, pageProps }: AppProps) {
  const ToastedComponent = notificationConnect(Component);
  return (
    <Root>
      <NotificationProvider itemsLimit={5} queueLimit={20}>
        <ToastConsumer horizontalPosition="center" fixed verticalPosition="bottom" />
        <ToastedComponent {...pageProps} />
      </NotificationProvider>
      <footer>
        <a
          href="https://thomasstep.com/google-calendar-for-helpdesk-privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="lc-card__text"
        >
          Privacy Policy
        </a>
        <a
          href="https://thomasstep.com/google-calendar-for-helpdesk-limited-use-disclosure"
          target="_blank"
          rel="noreferrer"
          className="lc-card__text"
        >
          Disclosure
        </a>
      </footer>
    </Root>
  );
}

export default App
